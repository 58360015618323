<template>
  <div>
    <!-- 联赛 -->
    <el-form
      v-loading="loading"
      label-width="200px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      class="demo-ruleForm"
    >
    <!-- 赛事 -->
      <el-form-item label="赛事" style="margin-bottom: 35px">
        <el-col :span="6">
          {{ match_types[type] }}
        </el-col>
      </el-form-item>
      <!-- 年份 -->
      <el-form-item
        label="年份"
        prop="year"
        label-width="200px"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-select
            v-if="view_type != 'edit'"
            v-model="dataForm.year"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in years"
              :value="item"
              :label="item"
              :key="`year_${index}`"
            ></el-option>
          </el-select>
          <div v-else>
            {{ dataForm.year }}
          </div>
        </el-col>
      </el-form-item>
      <!-- 比赛时间 -->
      <el-form-item label="比赛时间" required label-width="200px">
        <el-col :span="5">
          <el-form-item
            prop="match_time.match_start"
            id="match_time.match_start"
          >
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_match_start"
              type="datetime"
              placeholder="选择比赛开始时间"
              v-model="dataForm.match_time.match_start"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="match_time.match_end">
          <el-form-item prop="match_time.match_end">
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_match_end"
              type="datetime"
              placeholder="选择比赛结束时间"
              v-model="dataForm.match_time.match_end"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <!--  -->
      <el-form-item
        label="赛事规则"
        label-width="200px"
        prop="ext.rule"
        id="quillEditorQiniu"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.ext.rule"
          ref="customQuillEditor"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>

      <el-form-item
        label="赛事奖励"
        label-width="200px"
        prop="ext.prize"
        id="quillEditorQiniuPrize"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest1"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.ext.prize"
          ref="customQuillEditorPrize"
          :options="editorOptionPrize"
        >
        </quill-editor>
      </el-form-item>
      <!-- 报名宣传图 -->
      <el-form-item
        v-if="matchType != 'league'"
        label="报名宣传图"
        label-width="200px"
        prop="ext.adv_img"
        id="ext.adv_img"
      >
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeTopImageUpload"
          action="#"
          drag
          :http-request="httpUploadRequest"
        >
          <img
            v-if="dataForm.ext.adv_img"
            :src="dataForm.ext.adv_img"
            class="avatar"
          />
          <div v-else>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
          </div>
        </el-upload>
      </el-form-item>
      <!-- 提交，取消 -->
      <el-form-item style="margin-top: 50px;">
        <perButton
          type="primary"
          perm="armory:reporter:save"
          @click="submitForm()"
          size="large"
          >提交</perButton
        >
        <el-button size="large" @click="goBack()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ indent: "-1" }, { indent: "+1" }],
  [{ size: ["20rpx", "24rpx", "26rpx", "32rpx", "48rpx", "custom-size"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [
    {
      color: [
        "#000000",
        "#e60000",
        "#ff9900",
        "#ffff00",
        "#008a00",
        "#0066cc",
        "#9933ff",
        "#ffffff",
        "#facccc",
        "#ffebcc",
        "#ffffcc",
        "#cce8cc",
        "#cce0f5",
        "#ebd6ff",
        "#bbbbbb",
        "#f06666",
        "#ffc266",
        "#ffff66",
        "#66b966",
        "#66a3e0",
        "#c285ff",
        "#888888",
        "#a10000",
        "#b26b00",
        "#b2b200",
        "#006100",
        "#0047b2",
        "#6b24b2",
        "#444444",
        "#5c0000",
        "#663d00",
        "#666600",
        "#003700",
        "#002966",
        "#3d1466",
        "custom-color",
      ],
    },
    { background: [] },
  ], // dropdown with defaults from theme
  [{ align: [] }],
  ["image"],
  ["clean"], // remove formatting button
];

import { mapActions, mapState } from "vuex";
export default {
  name: "manager",
  components: {
    perButton,
  },
  props: {
    match_data: {
      type: Object,
    },
    // view_type: {
    //   type: String,
    // },
  },
  computed: {
    ...mapState("match", ["match_types"]),
    isEdit: function() {
      if (this.view_type == "edit") {
        return true;
      }

      return false;
    },
  },
  data() {
    let years = [];
    for (
      let i = new Date().getFullYear() + 1;
      i >= new Date().getFullYear() - 10;
      i--
    ) {
      years.push(i);
    }

    let self = this;
    const signupEndTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.sign_up_start !== "" &&
        self.dataForm.match_time.sign_up_end != ""
      ) {
        let sign_up_start = new Date(self.dataForm.match_time.sign_up_start);
        let sign_up_end = new Date(self.dataForm.match_time.sign_up_end);
        if (sign_up_end <= sign_up_start) {
          callback(new Error("结束时间必须大于开始时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    const reviewEndTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.review_start != "" &&
        self.dataForm.match_time.review_end != ""
      ) {
        let review_start = new Date(self.dataForm.match_time.review_start);
        let review_end = new Date(self.dataForm.match_time.review_end);
        if (review_end <= review_start) {
          callback(new Error("结束时间必须大于开始时间"));
          return;
        }
      }

      callback();
    };

    const matchStartTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.review_end != "" &&
        self.dataForm.match_time.match_start != ""
      ) {
        let review_end = new Date(self.dataForm.match_time.review_end);
        let match_start = new Date(self.dataForm.match_time.match_start);
        if (match_start < review_end) {
          callback(new Error("比赛开始时间必须大于等于审核结束时间"));
          return;
        }
      }

      callback();
    };

    const matchEndTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.match_start != null &&
        self.dataForm.match_time.match_end != null
      ) {
        let match_start = new Date(self.dataForm.match_time.match_start);
        let match_end = new Date(self.dataForm.match_time.match_end);
        if (match_end <= match_start) {
          callback(new Error("结束时间必须大于开始时间"));
          return;
        }
      }

      callback();
    };

    return {
      matchType: "",
      view_type: "edit",
      type: "s1",
      years: years,
      has_stage: false,
      org_rule: "",
      org_prize: "",
      dataForm: {
        _id: "",
        year: "",
        match_time: {
          match_start: null,
          match_end: null,
        },
        ext: {
          attestation: "",
          rule: "",
          prize: "",
          adv_img: "",
        },
      },
      rules: {
        year: [
          {
            required: true,
            message: "请选择年份",
            trigger: ["blur", "change"],
          },
        ],

        "match_time.match_start": [
          {
            type: "date",
            required: true,
            message: "请选择比赛开始日期",
            trigger: ["change", "blur"],
          },
          { validator: matchStartTimeCheckRule, trigger: ["change", "blur"] },
        ],
        "match_time.match_end": [
          {
            type: "date",
            required: true,
            message: "请选择比赛结束日期",
            trigger: ["change"],
          },
          { validator: matchEndTimeCheckRule, trigger: ["change"] },
        ],
        "ext.rule": [
          {
            required: true,
            message: "请输入赛事规则",
            trigger: ["blur", "change"],
          },
        ],
        "ext.prize": [
          {
            required: true,
            message: "请输入赛事奖励",
            trigger: ["blur", "change"],
          },
        ],
      },
      editorOption: {
        placeholder: "请填写赛事规则",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      editorOptionPrize: {
        placeholder: "请填写赛事奖励",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      loading: false,
    };
  },
  watch: {
    $route: {
      handler: function(val) {
        if (val.query.view_type == "add") {
          this.initData();
        }
        this.matchType = localStorage.getItem("matchType");
      },
      immediate: true,
    },
    match_data: {
      handler: function(val) {
        this.setFormData();
      },
      immediate: true,
    },
  },
  mounted() {
    // this.setFormData();
  },
  methods: {
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    validatorInteger0(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于或等于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) >= 0) {
        callback();
      } else {
        callback(new Error("请输入大于或等于0的整数"));
      }
    },
    validatorInteger2(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入整数"));
      }

      if (Number.isInteger(Number(value))) {
        callback();
      } else {
        callback(new Error("请输入整数"));
      }
    },
    getDateTime(dataStr) {
      if (dataStr != null) {
        return new Date(dataStr);
      }

      return null;
    },
    setFormData() {
      this.type = this.$route.query.type_id;
      this.view_type = this.$route.query.view_type;
      //如果没拿到数据。则代表为新建
      if (
        JSON.stringify(this.match_data) != "{}" &&
        JSON.stringify(this.match_data) != undefined
      ) {
        if (this.match_data.length != 0) {
          let data = this.match_data;
          Object.keys(this.dataForm).forEach((key) => {
            if (data[key]) {
              this.dataForm[key] = data[key];
            }
          });

          this.dataForm.match_time.sign_up_start = this.getDateTime(
            this.match_data.match_time.sign_up_start
          );
          this.dataForm.match_time.sign_up_end = this.getDateTime(
            this.match_data.match_time.sign_up_end || null
          );
          this.dataForm.match_time.review_start = this.getDateTime(
            this.match_data.match_time.review_start || null
          );
          this.dataForm.match_time.review_end = this.getDateTime(
            this.match_data.match_time.review_end || null
          );
          this.dataForm.match_time.match_start = this.getDateTime(
            this.match_data.match_time.match_start || null
          );
          this.dataForm.match_time.match_end = this.getDateTime(
            this.match_data.match_time.match_end || null
          );
          //如果不是联赛
          if (this.matchType != "league") {
            this.has_stage = !!this.match_data.has_stage;
          } else {
            //联赛没有阶段的概念，在编辑的时候不能修改
            this.has_stage = true;
          }

          this.org_rule = this.match_data.ext.rule;
          this.org_prize = this.match_data.ext.prize;
        }
      } else {
        this.initData();
      }
    },
    initData() {
      this.dataForm = {
        _id: "",
        year: "",
        match_time: {
          sign_up_start: null,
          sign_up_end: null,
          review_start: null,
          review_end: null,
          match_start: null,
          match_end: null,
        },

        step: "",

        ext: {
          attestation: "",
          rule: "",
          prize: "",
          adv_img: "",
        },
      };
    },
    //返回上一次
    goBack() {
      this.$router.push(`/match/info/info_s1/list`);
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData();
          if (this.org_rule != this.dataForm.ext.rule) {
            let rule_url = await this.uploadContent(
              "rule",
              this.dataForm.ext.rule
            );
            if (!rule_url || !rule_url.length) {
              this.$message.error("保存失败, 上传赛事规则失败");
              return;
            }

            d.ext.rule_url = rule_url;
          }

          if (this.org_prize != this.dataForm.ext.prize) {
            let prize_url = await this.uploadContent(
              "prize",
              this.dataForm.ext.prize
            );
            if (!prize_url || !prize_url.length) {
              this.$message.error("保存失败, 上传赛事奖励失败");
              return;
            }

            d.ext.prize_url = prize_url;
          }
          //如果等于门派联赛s2
          if (this.type == "s2_pro") {
            d.type_id = this.type;
            let { data, errorCode } = await this.$http.match.save_s2_pro(
              this.type,
              d
            );
            if (errorCode != "0000") {
              this.$message.error("保存失败");
              return;
            }

            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.$bus.$emit("onMatchChanged", data);
          } else {
            let { data, errorCode } = await this.$http.match.save(this.type, d);
            if (errorCode != "0000") {
              this.$message.error("保存失败");
              return;
            }

            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.$bus.$emit("onMatchChanged", data);
          }

          this.removeTabByPath(`/match/info/info_s1/add`);
          this.removeTabByPath(`/match/info/info_s1/edit`);
          this.$router.push(`/match/info/info_s1/list`);
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (id1 == "desc") {
              id1 = "quillEditorQiniu";
            }

            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
      });
    },
    getSaveData() {
      let data = {
        ...this.dataForm,
      };

      console.log(data);
      return data;
    },
    //对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.dataForm.ext.adv_img = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    async httpUploadEditorRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    async httpUploadEditorRequest1(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditorPrize.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    async uploadContent(t, d) {
      let blob = new Blob([d], { type: "text/html;charset=utf-8" });

      // 这么写是因为文件转换是异步任务
      let transToFile = async (blob, fileName, fileType) => {
        return new window.File([blob], fileName, {
          type: fileType,
          charset: "utf-8",
        });
      };

      let fileName = t + "_" + randomStr() + ".html";
      let textContain = transToFile(blob, fileName, "application/json");

      // 转换完成后可以将file对象传给接口
      return await new Promise((resolve, reject) => {
        textContain
          .then(async (res) => {
            let file = res;
            console.log(res);
            let path = uploadOssUrl().richText + this.type + "/" + fileName;
            let result = await this.$http.uploadFile(path, fileName, file);
            resolve(result);
          })
          .catch(() => {
            resolve(null);
          });
      });
    },
    cancelDialog() {},
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>
<style src="../../../editor.css"></style>
<style lang="less" scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 667px;
}

/*/deep/ .el-form-item__label {*/
/*  width: 150px!important;*/
/*}*/

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 178px;
  height: 178px;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}

/deep/ #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

/deep/ #quillEditorQiniuPrize .el-form-item__content {
  height: 100%;
}

/deep/ .ql-picker-item[data-value="custom-color"] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value="custom-color"]::before {
  content: "自定义颜色";
  color: black;
  width: fit-content;
}
</style>
